import React from "react";

export default class Footer extends React.Component {
  render() {
    return (
      <footer id="footer" className="footer">
        <div className="footer-container w-container">
          <div className="footer-flex-container">
            <a href="#" className="footer-logo-link w-inline-block">
              <img
                src={require("./../../assets/images/f4d-black-transparent.png")}
                sizes="81.75px"
                alt
                className="footer-image"
              />
            </a>
          </div>
          <div className="footer-copyright">
            Copyright © 2020 Fits4Days. All rights reserved.
          </div>
        </div>
      </footer>
    );
  }
}
