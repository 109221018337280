import React from "react";
import { Card, Accordion, Button } from "react-bootstrap";
import Fade from "react-reveal/Fade";

export default class FitsCard extends React.Component {
  render() {
    return (
      <div className="fit-card">
        <div className="fit-image-container">
          <img
            src={this.props.fit.img_url}
            sizes="(max-width: 479px) 278px, 348px"
            alt
            className="fits-img"
          />
        </div>
        {/* <div className="fits-details-container">
          <a href="#" className="fits-details-link">
            View fits details
          </a>
        </div> */}
        <Accordion style={{ zIndex: 99 }}>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="light" eventKey="0">
                View fits details
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {this.props.fit.details.map((item, index) => {
                  return (
                    <div>
                      <p>
                        <a
                          style={{ color: "black" }}
                          key={index}
                          target="_blank"
                          href={
                            item.link.trim() == "null" ? "#" : item.link.trim()
                          }
                        >
                          - {item.title}
                        </a>
                      </p>
                      <div className="fits-card-seperator" />
                    </div>
                  );
                })}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    );
  }
}
