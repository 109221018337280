import React from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import Register from "../pages/auth/Register";
import Login from "../pages/auth/Login";

export default class LoginRouter extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/" render={() => <Redirect to="/register" />}>
            <Register />
          </Route>
        </Switch>
      </Router>
    );
  }
}
