import React from "react";
import {
  Nav,
  Navbar,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { logoutUser } from "./../../utils/Firebase/auth";

export default class MainNav extends React.Component {
  render() {
    let username = JSON.parse(localStorage.getItem("user"));
    username = username.full_name.split(" ")[0];
    return (
      <Navbar
        bg="light"
        expand="lg"
        className="sticky-top"
        style={{
          paddingTop: 12,
          paddingLeft: 64,
          paddingRight: 90,
          paddingBottom: 12,
        }}
      >
        <Navbar.Brand href="/">
          <img
            src={require("./../../assets/images/f4d-black-transparent.png")}
            height="45"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/services">Services</Nav.Link>
            <Nav.Link href="/discounts">Discounts</Nav.Link>
            <NavDropdown
              title={<span className="text-p my-auto">Hi {username}</span>}
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item style={{ fontSize: 12 }} href="/account">
                Account
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                style={{ fontSize: 12 }}
                onClick={() => logoutUser()}
              >
                Log out
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
