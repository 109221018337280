import React from "react";
import PlainNav from "../../components/navigation/PlainNav";
import { registerUser } from "./../../utils/Firebase/auth";
import Fade from "react-reveal/Fade";
import { Modal, Spinner } from "react-bootstrap";

export default class Register extends React.Component {
  state = {
    full_name: "",
    email: "",
    handle: "@",
    password: "",
    showModal: false,
  };

  handleRegister = (event) => {
    event.preventDefault();
    this.setState({ showModal: true });
    registerUser({
      full_name: this.state.full_name,
      email: this.state.email.toLowerCase(),
      handle: this.state.handle,
      password: this.state.password,
    })
      .then()
      .catch((err) => {
        this.setState({ showModal: false });
        alert(err.message);
      });
  };

  render() {
    return (
      <React.Fragment className="black-bg">
        <PlainNav className="black-bg" />
        <div className="signup-body">
          <section id="contact-form" className="onboarding-form black-bg">
            <div className="dim-view" />
            <div
              data-w-id="c26e6013-a613-dd35-a7df-cc3a4f3a587f"
              className="onboarding-card"
            >
              <h2 className="onboarding-title">Create an account</h2>
              <p className="onboarding-paragraph">
                helping you find the perfect fit.
              </p>
              <div
                style={{ fontSize: 12, marginTop: 48 }}
                id="formInstructions"
                className="service-form-p-it"
              >
                <em>Fields marked with an asterisk (*) are required.</em>
              </div>

              <div className="onboarding-form-block w-form">
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  className="inner-oboarding-form"
                  onSubmit={this.handleRegister}
                >
                  <label htmlFor="name-2">full name *</label>
                  <input
                    type="text"
                    className="w-input"
                    maxLength={256}
                    name="name"
                    data-name="Name"
                    placeholder
                    id="name"
                    required
                    value={this.state.full_name}
                    onChange={(newValue) => {
                      this.setState({
                        full_name: newValue.target.value,
                      });
                    }}
                  />
                  <label htmlFor="Handle">Instagram Handle</label>
                  <input
                    type="text"
                    className="w-input"
                    maxLength={256}
                    name="Handle"
                    data-name="Handle"
                    placeholder
                    id="Handle"
                    value={this.state.handle}
                    onChange={(newValue) => {
                      this.setState({
                        handle: newValue.target.value,
                      });
                    }}
                  />
                  <label htmlFor="email">Email Address *</label>
                  <input
                    type="email"
                    className="w-input"
                    maxLength={256}
                    name="email"
                    data-name="Email"
                    placeholder
                    id="email"
                    required
                    value={this.state.email}
                    onChange={(newValue) => {
                      this.setState({
                        email: newValue.target.value,
                      });
                    }}
                  />
                  <label htmlFor="Password">Password *</label>
                  <input
                    type="password"
                    className="w-input"
                    maxLength={256}
                    name="Password"
                    data-name="Password"
                    placeholder
                    id="Password"
                    required
                    onChange={(newValue) => {
                      this.setState({
                        password: newValue.target.value,
                      });
                    }}
                  />
                  <span style={{ marginTop: 32 }}>Have an acount?</span>
                  <a className="onboarding-switch" href="/login">
                    {" "}
                    Login
                  </a>
                  <br />
                  <br />
                  <span style={{ fontSize: 12 }}>
                    By signing up you agree to our{" "}
                  </span>
                  <a
                    style={{ fontSize: 12 }}
                    href="https://firebasestorage.googleapis.com/v0/b/fits4days-8932b.appspot.com/o/admin%2FTerms%20and%20conditions%20for%20supply%20of%20services%20to%20consumers%20via%20a%20website.pdf?alt=media&token=6aa09131-bd26-46c7-9893-6099e946988d"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                  <input
                    defaultValue="Register"
                    data-wait="Please wait..."
                    className="onboarding-submit-btn"
                    type="submit"
                  />
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="benefits">
            <div className="container-6 w-container">
              <h1 style={{ opacity: 100, color: "white" }}>
                We are a Virtual styling page dedicated to providing outfit
                inspiration for every occasion.
              </h1>
              <h4
                style={{
                  opacity: 0.6,
                  color: "white",
                  textAlign: "center",
                  marginTop: 32,
                  marginBottom: 0,
                  paddingBottom: 0,
                }}
              >
                With Fits4Days Premium we have created a space where our clients
                can keep up with new drops & the latest trends whilst also being
                privy to, exclusive discounts and monthly perks from selected
                lifestyle and fashion brands.
              </h4>
              <h3
                style={{
                  textAlign: "center",
                  color: "white",
                  marginTop: 0,
                  paddingTop: 0,
                  marginBottom: 32,
                  fontSize: 17,
                }}
              >
                All for Just 3.50/a month or £35/a year
              </h3>
              <div
                className="benefits-seperator"
                style={{ marginTop: 0, paddingTop: 0 }}
              />
              <div className="benefiits-container">
                <div className="benefiits-grid">
                  <Fade left>
                    <div
                      id="w-node-41190d05a77d-31a4f95d"
                      data-w-id="40450e68-a102-740f-1a84-41190d05a77d"
                      className="benefiits-text-container"
                    >
                      <h2 className="benefits-heading">sourcing items</h2>
                      <p className="benefits-para">
                        If you need help finding an item you have seen or would
                        like something similar sourced
                      </p>
                    </div>
                  </Fade>
                  <Fade right>
                    <div
                      id="w-node-6a597ab152e9-31a4f95d"
                      className="benefiits-image-container"
                    >
                      <img
                        src={require("./../../assets/images/PHOTO-2020-07-22-21-12-24.jpg")}
                        data-w-id="f9edcce2-8501-a9f2-e02f-9fabc6bc05de"
                        alt
                        className="benefits-img"
                      />
                    </div>
                  </Fade>
                </div>
                <div className="benefits-seperator" />
              </div>
              <div className="benefiits-container">
                <div className="benefiits-grid">
                  <Fade left>
                    <div
                      id="w-node-25d0d035448b-31a4f95d"
                      className="benefiits-image-container web"
                    >
                      <img
                        src={require("./../../assets/images/account-img.jpeg")}
                        data-w-id="977657c4-c629-8691-f20c-25d0d035448c"
                        alt
                        className="benefits-img"
                      />
                    </div>
                  </Fade>
                  <Fade right>
                    <div
                      id="w-node-3b07adf919ef-31a4f95d"
                      data-w-id="01dce90a-962c-cca8-3e54-3b07adf919ef"
                      className="benefiits-text-container"
                    >
                      <h2 className="benefits-heading">
                        Virtual Personal Styling
                      </h2>
                      <p className="benefits-para">
                        Personally tailored outfit scrapbook for whatever
                        occasion on request.
                      </p>
                    </div>
                  </Fade>
                  <Fade left>
                    <div
                      id="w-node-3b07adf919f4-31a4f95d"
                      className="benefiits-image-container mobile"
                    >
                      <img
                        src={require("./../../assets/images/account-img.jpeg")}
                        data-w-id="01dce90a-962c-cca8-3e54-3b07adf919f5"
                        alt
                        className="benefits-img"
                      />
                    </div>
                  </Fade>
                </div>
                <div className="benefits-seperator" />
              </div>
              <div className="benefiits-container">
                <div className="benefiits-grid mb-64">
                  <Fade left>
                    <div
                      id="w-node-35f741ddc961-31a4f95d"
                      data-w-id="c166eed3-26b3-e190-325e-35f741ddc961"
                      className="benefiits-text-container"
                    >
                      <h2 className="benefits-heading">Fashion SOS</h2>
                      <p className="benefits-para">
                        Helping you style any item of clothing you have or wish
                        to purchase
                      </p>
                    </div>
                  </Fade>
                  <Fade right>
                    <div
                      id="w-node-35f741ddc966-31a4f95d"
                      className="benefiits-image-container"
                    >
                      <img
                        src={require("./../../assets/images/PHOTO-2020-07-22-21-13-35.jpg")}
                        sizes="(max-width: 479px) 87vw, (max-width: 767px) 250px, 350px"
                        data-w-id="c166eed3-26b3-e190-325e-35f741ddc967"
                        alt
                        className="benefits-img"
                      />
                    </div>
                  </Fade>
                </div>
                <div className="benefits-seperator" />
              </div>
              <Fade right>
                <div className="benefiits-container">
                  <h2
                    style={{ textAlign: "center" }}
                    className="benefits-heading"
                  >
                    Monthly newsletter
                  </h2>
                  <p style={{ textAlign: "center" }} className="benefits-para">
                    F4D take on fashion and what we think is hot or not + little
                    tips to keep you trendy and ahead of the curve
                  </p>
                  <div className="benefits-seperator" />
                </div>
              </Fade>
              <Fade left>
                <div className="benefiits-container">
                  <h2
                    style={{ textAlign: "center" }}
                    className="benefits-heading"
                  >
                    Discount codes
                  </h2>
                  <p style={{ textAlign: "center" }} className="benefits-para">
                    - Exclusive discount codes from selected brands
                  </p>
                  <p style={{ textAlign: "center" }} className="benefits-para">
                    - Perks from collaborative brands working with F4D
                  </p>
                  <div className="benefits-seperator hidden" />
                </div>
              </Fade>
            </div>
          </div>
        </div>
        <Modal style={{ marginTop: 160 }} show={this.state.showModal}>
          <Modal.Header>
            <Modal.Title style={{ textAlign: "center", margin: "auto" }}>
              Loading
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: 90 }}>
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Spinner animation="border" />
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

// F I T S 4 D A Y S
// - Helping you find the perfect Fit

// We are a Virtual styling page dedicated to providing outfit inspiration for every occasion.

// With Fits4Days Premium we have created a space where our clients can keep up with new drops & the latest trends whilst also being privy to, exclusive discounts and monthly perks from selected lifestyle and fashion brands.
// —————-
// Our Unique Services for subscribers

// - [ ] Sourcing items
//     - [ ] If you need help finding an item you have seen or would like something similar sourced
// - [ ] Virtual Personal styling
//     - [ ] Personally tailored outfit scrapbook for whatever occasion on request
// - [ ] Fashion SOS
//     - [ ] Helping you style any item of clothing you have or wish to purchase
// - [ ] Monthly newsletter
//     - [ ] F4D take on fashion and what we think is hot or not + little tips to keep you trendy and ahead of the curve
// - [ ] Discount codes
//     - [ ] Exclusive discount codes from selected brands
//     - [ ]  Perks from collaborative brands working with F4D
