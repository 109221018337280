import firebase from "./Firebase";
import "firebase/firestore";

// let db = firebase.firestore();

export const loginUser = (email, password) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((error) => {
        console.log("Error", error.message);
        reject(error);
        // this.setState({ isLoading: false });
      })
      .then((response) => {
        if (response) {
          console.log("Successfully logged in user!");
          const uid = response.user.uid;
          window.location.href = "/";
          resolve();
          // fetchUser(uid);
          // this.setState({ isSuccessful: true });
        }
      });
  });
};

export const resetPassword = (email) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        // Email sent.
        alert("Reset email sent! Check your inbox");
        resolve();
      })
      .catch(function (error) {
        // An error happened.
        alert("Error:", error);
        reject(error);
      });
  });
};

export const deleteAccount = () => {
  return new Promise((resolve, reject) => {
    var user = firebase.auth().currentUser;
    const uid = user.uid;
    user
      .delete()
      .then(function () {
        // User deleted.
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .delete()
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch(function (error) {
        // An error happened.
        reject(error);
      });
  });
};

export const registerUser = (data) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(data.email, data.password)
      .then((result) => {
        const user = result.user;
        console.log("Successfully registered user!", user);

        if (user) {
          data.uid = user.uid;
          data.is_subscribed = false;
          data.date_created = Date();
          delete data.password;
          firebase
            .firestore()
            .collection("users")
            .doc(data.uid)
            .set(data, { merge: true })
            .then(() => {
              // var loggedUser = User.getInstance();
              // loggedUser.fetch(data);
              fetchUser(data.uid);
              resolve();
              console.log("Successfully saved user to firestore!");
              setTimeout(() => {
                // this.setState({ isSuccessful: false });
                // update firebase login status
                window.location.href = "/subscription";
              }, 2000);
            })
            .catch((err) => {
              reject(err);
              console.log("Error", err.message);
            });
        }
      })
      .catch((err) => {
        console.log("Error", err.message);
        reject(err);
      });
  });
};

export const saveToFireStore = (uid, data) => {
  firebase
    .firestore()
    .collection("users")
    .doc(uid)
    .set(data, { merge: true })
    .then(() => {
      console.log("Successfully updated user to firestore!");
      setTimeout(() => {
        // this.setState({ isSuccessful: false });
        // update firebase login status
      }, 1000);
    })
    .catch((err) => {
      console.log("Error", err.message);
    });
};

export const fetchUser = () => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          localStorage.setItem("user", JSON.stringify(doc.data()));
          resolve(doc.data());
        }
      })
      .catch((err) => reject(err));
  });
};

// export const fetchReadings = () => {
//   var readings = [];
//   return new Promise((resolve, reject) => {
//     firebase
//       .firestore()
//       .collection("readings")
//       .get()
//       .then((docs) => {
//         docs.forEach((doc) => {
//           readings.push(doc.data());
//         });
//         resolve(readings);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

// export const fetchChurch = (id) => {
//   return new Promise((resolve, reject) => {
//     firebase
//       .firestore()
//       .collection("churches")
//       .doc(id)
//       .get()
//       .then((doc) => {
//         if (doc.exists) {
//           resolve(doc.data());
//         }
//       })
//       .catch((err) => reject(err));
//   });
// };

// export const fetchChurchAsync = (id) => {
//   firebase
//     .firestore()
//     .collection("churches")
//     .doc(id)
//     .get()
//     .then((church) => {
//       return church;
//     })
//     .catch((err) => Alert.alert("Error", err.message));
// };

// export const fetchChurches = () => {
//   var churches = [];
//   return new Promise((resolve, reject) => {
//     firebase
//       .firestore()
//       .collection("churches")
//       .get()
//       .then((docs) => {
//         docs.forEach((doc) => {
//           churches.push(doc.data());
//         });
//         resolve(churches);
//       })
//       .catch((err) => reject(err));
//   });
// };

export const logoutUser = () => {
  firebase
    .auth()
    .signOut()
    .then(function () {
      console.log("Successfully logged out!");
    })
    .catch(function (error) {
      console.log(error);
    });
};
