import React from "react";
import MainNav from "../components/navigation/MainNav";
import Footer from "../components/navigation/Footer";
import Fade from "react-reveal/Fade";

export default class Services extends React.Component {
  render() {
    return (
      <React.Fragment>
        <MainNav />
        <div className="services">
          <h1 className="service-h1">Services</h1>
          <div className="service-seperator" />
          <div className="services-container w-container">
            <div className="services-inner-container">
              <div className="service">
                <Fade left>
                  <div
                    id="w-node-c53195a23fd1-95a23fca"
                    className="service-text-container"
                  >
                    <div className="service-text-group">
                      <div className="service-title-group">
                        <div className="heading-line" />
                        <div className="heading-line pink" />
                        <h2 className="service-title">sourcing items</h2>
                      </div>
                      <div className="service-description">
                        {" "}
                        If you need help finding an item you have seen or would
                        like something similar sourced{" "}
                      </div>
                      <a href="services/sourcing-items" className="service-cta">
                        Book now
                      </a>
                    </div>
                  </div>
                </Fade>
                <Fade right>
                  <div
                    id="w-node-c53195a23fdc-95a23fca"
                    className="service-image-container"
                  >
                    <img
                      src={require("./../assets/images/PHOTO-2020-07-22-21-12-24.jpg")}
                      sizes="(max-width: 479px) 87vw, (max-width: 767px) 42vw, (max-width: 991px) 40vw, 370px"
                      alt
                      className="service-img"
                    />
                  </div>
                </Fade>
              </div>

              <div className="service">
                <Fade left>
                  <div className="service-image-container web">
                    <img
                      src={require("./../assets/images/account-img.jpeg")}
                      className="image-10"
                    />
                  </div>
                </Fade>
                <Fade right>
                  <div
                    id="w-node-c53195a23fe1-95a23fca"
                    className="service-text-container"
                  >
                    <div className="service-text-group">
                      <div className="service-title-group">
                        <div className="heading-line" />
                        <div className="heading-line pink" />
                        <h2 className="service-title">
                          Virtual Personal Styling
                        </h2>
                      </div>
                      <div className="service-description">
                        Personally tailored outfit scrapbook for whatever
                        occasion on request.
                      </div>
                      <a
                        href="services/virtual-personal-styling"
                        className="service-cta"
                      >
                        Book now
                      </a>
                    </div>
                  </div>
                </Fade>
                <Fade left>
                  <div className="service-image-container mobile">
                    <img
                      src={require("./../assets/images/account-img.jpeg")}
                      alt
                      className="service-img"
                    />
                  </div>
                </Fade>
              </div>

              <div className="service">
                <Fade left>
                  <div
                    id="w-node-c53195a23fef-95a23fca"
                    className="service-text-container"
                  >
                    <div className="service-text-group">
                      <div className="service-title-group">
                        <div className="heading-line" />
                        <div className="heading-line pink" />
                        <h2 className="service-title">Fashion SOS</h2>
                      </div>
                      <div className="service-description">
                        Helping you style any item of clothing you have or wish
                        to purchase{" "}
                      </div>
                      <a href="services/fashion-sos" className="service-cta">
                        Book now
                      </a>
                    </div>
                  </div>
                </Fade>

                <Fade right>
                  <div className="service-image-container">
                    <img
                      src={require("./../assets/images/PHOTO-2020-07-22-21-13-35.jpg")}
                      sizes="(max-width: 479px) 87vw, (max-width: 767px) 42vw, (max-width: 991px) 40vw, 370px"
                      alt
                      className="service-img"
                    />
                  </div>
                </Fade>
              </div>
            </div>
            <Fade left>
              <div
                style={{
                  marginTop: 90,
                  display: "flex",
                  flexDirection: "column",
                }}
                className=""
              >
                <h2 style={{ textAlign: "center", marginTop: 32 }} className="">
                  Special Request
                </h2>
                <p
                  style={{
                    margin: "auto",
                    maxWidth: 500,
                    padding: 16,
                    textAlign: "center",
                  }}
                  className=""
                >
                  All special requests are subject to an additional fee
                  depending on the type of request
                </p>
                <a
                  style={{ margin: "auto" }}
                  href="services/fashion-sos"
                  className="service-cta"
                  href="mailto:info@fits4days.co.uk"
                >
                  Email us!
                </a>
              </div>
            </Fade>
          </div>
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}
