import React from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import Home from "../pages/Home";
import Services from "../pages/Services";
import Discounts from "../pages/Discounts";
import Account from "../pages/Account";
import Subscription from "../pages/auth/Subscription";
import Register from "../pages/auth/Register";
import SourcingItemsForm from "../pages/forms/SourcingItemsForm";
import VirtualPersonalStylingForm from "../pages/forms/VirtualPersonalStylingForm";
import FashionSOSForm from "../pages/forms/FashionSOSForm";
import Login from "../pages/auth/Login";

export default class MainRouter extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          {/* Screens */}
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/services">
            <Services />
          </Route>
          <Route exact path="/discounts">
            <Discounts />
          </Route>
          <Route exact path="/account">
            <Account />
          </Route>
          <Route exact path="/subscription">
            <Subscription />
          </Route>
          {/* Auth */}

          <Route path="/register" render={() => <Redirect to="/" />}>
            <Home />
          </Route>
          <Route path="/login" render={() => <Redirect to="/" />}>
            <Home />
          </Route>
          {/* Forms */}
          <Route exact path="/services/fashion-sos">
            <FashionSOSForm />
          </Route>
          <Route exact path="/services/sourcing-items">
            <SourcingItemsForm />
          </Route>
          <Route exact path="/services/virtual-personal-styling">
            <VirtualPersonalStylingForm />
          </Route>
        </Switch>
      </Router>
    );
  }
}

// export default withRouter((props) => <MainRouter {...props} />);
