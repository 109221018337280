import React from "react";
import Footer from "../../components/navigation/Footer";
import PlainNav from "../../components/navigation/PlainNav";
import {
  fetchStripeMonthlySession,
  fetchStripeYearlySession,
} from "./../../utils/Firebase/cloud";
import { loadStripe } from "@stripe/stripe-js";
import { Modal, Button, Spinner } from "react-bootstrap";

const stripePromise = loadStripe(
  "pk_live_51H0CviA1PaOpIWZfxcmUUgtpc4Nj51KAjfaHrrvMVh0YnsOvE3GSgNRZ48BOVaAWfoe417aE6QOkZ62bjWSR2LIM00kFPjZSmX"
);

export default class Subscription extends React.Component {
  state = {
    showModal: false,
  };

  handleMonthly = async (event) => {
    event.preventDefault();
    this.setState({ showModal: true });
    // Call your backend to create the Checkout session.
    // const session = await fetchStripeSession();

    fetchStripeMonthlySession()
      .then(async (session) => {
        console.log("str session:", session);
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: session.id,
        });
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
        console.log("errar:", error.message);
      })
      .catch((err) => {
        console.log("errir:", err);
      });
  };
  handleYearly = async (event) => {
    event.preventDefault();
    this.setState({ showModal: true });
    // Call your backend to create the Checkout session.
    // const session = await fetchStripeSession();

    fetchStripeYearlySession()
      .then(async (session) => {
        console.log("str session:", session);
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: session.id,
        });
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
        console.log("errar:", error.message);
      })
      .catch((err) => {
        console.log("errir:", err);
      });
  };

  render() {
    return (
      <React.Fragment>
        <PlainNav dark />
        <div className="subscriptions">
          <div className="subscription-container w-container">
            <h2 className="subs-h1">Select a subscription model</h2>
            <p className="subs-p">
              With Fits4days premium we have created a space where our clients
              can keep up with new drops &amp; the latest trends whilst also
              being privy to,
              <br />‍<br />
              exclusive discounts and monthly perks from selected lifestyle and
              fashion brands.
            </p>
            <div className="subscriptions-grid">
              <div
                id="w-node-47585aab7ecb-c2871bb6"
                className="subscription-card"
              >
                <div className="subs-titile-grp">
                  <h2 className="subs-price">£35/y</h2>
                  <div className="subs-sub">billed yearly</div>
                </div>
                <div className="subs-grey-sub">what's included</div>
                <div className="subs-seperator" />
                <ul role="list" className="list">
                  <li>
                    <div>4 requests / month</div>
                  </li>
                  <li>
                    <div>sourcing items</div>
                  </li>
                  <li className="list-item">
                    <div>virtual personal styling</div>
                  </li>
                  <li>
                    <div className="text-block-11">fashion SOS</div>
                  </li>
                  <li>
                    <div className="text-block-12">
                      monthly newsletter
                      <br />
                    </div>
                  </li>
                  <li>
                    <div className="text-block-13">discount codes</div>
                  </li>
                </ul>
                <a onClick={this.handleYearly} className="subs-btn">
                  select plan
                </a>
              </div>
              <div
                id="w-node-5b24b5affdf1-c2871bb6"
                className="subscription-card"
              >
                <div className="subs-titile-grp">
                  <h2 className="subs-price">£3.5/m</h2>
                  <div className="subs-sub">billed monthly</div>
                </div>
                <div className="subs-grey-sub">what's included</div>
                <div className="subs-seperator" />
                <ul role="list" className="list">
                  <li>
                    <div>3 requests / month</div>
                  </li>
                  <li>
                    <div>sourcing items</div>
                  </li>
                  <li className="list-item">
                    <div>virtual personal styling</div>
                  </li>
                  <li>
                    <div className="text-block-11">fashion SOS</div>
                  </li>
                  <li>
                    <div className="text-block-12">
                      monthly newsletter
                      <br />
                    </div>
                  </li>
                  <li>
                    <div className="text-block-13">discount codes</div>
                  </li>
                </ul>
                <a onClick={this.handleMonthly} className="subs-btn">
                  select plan
                </a>
              </div>
            </div>
            <h5 style={{ marginTop: 64, textAlign: "center" }}>
              - [ ] All services offered by Fits 4 Days are limited to 3 or 4
              requests per service per subscriber a month
            </h5>
            <h5 style={{ textAlign: "center" }}>
              - [ ] If a subscriber requires more than (Basic standard service)
              they must then put their request through the special request tab
              which is subject to an additional fee on request
            </h5>
          </div>
        </div>
        <Modal style={{ marginTop: 160 }} show={this.state.showModal}>
          <Modal.Header>
            <Modal.Title style={{ textAlign: "center", margin: "auto" }}>
              Loading
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: 90 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              Loading Stripe Checkout...
              <Spinner style={{ marginTop: 24 }} animation="border" />
            </div>
          </Modal.Body>
        </Modal>
        <Footer />
      </React.Fragment>
    );
  }
}
