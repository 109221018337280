import React from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import Subscription from "../pages/auth/Subscription";

export default class SubscriptionRouter extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" render={() => <Redirect to="/subscription" />}>
            <Subscription />
          </Route>
        </Switch>
      </Router>
    );
  }
}
