import firebase from "./Firebase";
import "firebase/firestore";

export const fetchDiscountCodes = () => {
  var codes = [];
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("discount_codes")
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          codes.push(doc.data());
        });
        resolve(codes);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const fetchFits = () => {
  var fits = [];
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("fits")
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          fits.push(doc.data());
        });
        resolve(fits);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchSubscriptionDetails = (email) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("subscriptions")
      .where("customer_email", "==", email)
      .get()
      .then((doc) => {
        console.log("email:", email);
        console.log("doc:", doc);
        // resolve(doc.data());
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const subscribeUser = () => {
  firebase
    .firestore()
    .collection("users")
    .doc(firebase.auth().currentUser.uid)
    .set(
      {
        is_subscribed: true,
      },
      { merge: true }
    )
    .then(() => {
      window.location.href = "/";
    });
};
export const unSubscribeUser = () => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .set(
        {
          is_subscribed: false,
          subcription_id: null,
          subscription_type: null,
        },
        { merge: true }
      )
      .then((res) => {
        console.log("unsubscribed");
        resolve();
      })
      .catch((err) => {
        reject(err);
        alert(err.message);
      });
  });
};
// export const fetchChurch = (id) => {
//   return new Promise((resolve, reject) => {
//     firebase
//       .firestore()
//       .collection("churches")
//       .doc(id)
//       .get()
//       .then((doc) => {
//         if (doc.exists) {
//           resolve(doc.data());
//         }
//       })
//       .catch((err) => reject(err));
//   });
// };

// export const fetchChurchAsync = (id) => {
//   firebase
//     .firestore()
//     .collection("churches")
//     .doc(id)
//     .get()
//     .then((church) => {
//       return church;
//     })
//     .catch((err) => Alert.alert("Error", err.message));
// };

// export const fetchChurches = () => {
//   var churches = [];
//   return new Promise((resolve, reject) => {
//     firebase
//       .firestore()
//       .collection("churches")
//       .get()
//       .then((docs) => {
//         docs.forEach((doc) => {
//           churches.push(doc.data());
//         });
//         resolve(churches);
//       })
//       .catch((err) => reject(err));
//   });
// };

export const logoutUser = () => {
  firebase
    .auth()
    .signOut()
    .then(function () {
      console.log("Successfully logged out!");
    })
    .catch(function (error) {
      console.log(error);
    });
};
