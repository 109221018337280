import React from "react";
import { logoutUser } from "./../../utils/Firebase/auth";

export default class PlainNav extends React.Component {
  render() {
    const theme = this.props.dark ? "dark.png" : "light.png";
    return (
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration={400}
        role="banner"
        className="subs-nav w-nav"
      >
        <div className="w-container">
          <a href="#" className="w-nav-brand">
            <img
              src={require("./../../assets/images/f4d-" + theme)}
              sizes="70px"
              alt
              className="image-8"
            />
          </a>
          <div className="plain-nav-button">
            <a
              onClick={() => {
                logoutUser();
              }}
            >
              Logout
            </a>
          </div>
        </div>
      </div>
    );
  }
}
