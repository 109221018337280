import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

export default class DiscountCard extends React.Component {
  render() {
    return (
      <div class="discount-card">
        <div class="discount-img-container">
          <img
            style={{ width: "100%", height: "100%" }}
            src={this.props.discount.logo}
          />
        </div>
        <h3 class="discount-title">{this.props.discount.brand}</h3>
        <Accordion style={{ zIndex: 99 }}>
          <Card>
            <Accordion.Toggle
              style={{
                backgroundColor: "#5cc9ad",
                border: "none",
                color: "white",
                borderRadius: 0,
              }}
              as={Button}
              variant="light"
              eventKey="0"
            >
              <p style={{ margin: "auto" }}></p>View Code
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>{this.props.discount.code}</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        {/* 
        <h3 class="discount-title">{this.props.discount.brand}</h3>
        <a href="#" class="discount-btn disabled">
          {this.props.discount.code}
        </a> */}
      </div>
    );
  }
}
