import React from "react";
import Footer from "../components/navigation/Footer";
import MainNav from "../components/navigation/MainNav";
import { logoutUser, deleteAccount } from "./../utils/Firebase/auth";
import { Modal, Spinner, Button } from "react-bootstrap";
import { cancelSubscription } from "./../utils/Firebase/cloud";

export default class Account extends React.Component {
  state = {
    showModal: false,
    showLoadingModal: false,
  };
  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  handleDeleteAccount = () => {
    deleteAccount()
      .then(() => {
        logoutUser();
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  handleCancelSubscription = (subscription_id, stripe_customer_id) => {
    this.setState({ showModal: false, showLoadingModal: true });
    cancelSubscription(subscription_id, stripe_customer_id)
      .then((message) => {
        console(message);
        alert("Your Subscription was successfully cancelled");
        logoutUser();
      })
      .catch((err) => {
        this.setState({ showLoadingModal: false });
      });
  };

  render() {
    const user = JSON.parse(localStorage.getItem("user"));
    const lastInitial =
      user.full_name.split(" ").length > 1
        ? user.full_name.split(" ")[1][0]
        : "";
    const intials = user.full_name.split(" ")[0][0] + lastInitial;
    return (
      <div className="account-body">
        <MainNav />
        <div className="account">
          <div className="account-container">
            <h1 className="account-h1">Account</h1>
            <div className="account-grid">
              <div
                id="w-node-77965b1153b6-02c2a9c2"
                className="account-text-grid"
              >
                <div
                  id="w-node-4fcc95836201-02c2a9c2"
                  className="account-avatar"
                >
                  <h1 className="avatar-initials">{intials}</h1>
                </div>
                <div className="account-user-group">
                  <div className="account-fullname">{user.full_name}</div>
                  <div className="account-email">{user.email}</div>
                </div>
                <ol
                  id="w-node-070e8567e116-02c2a9c2"
                  role="list"
                  className="account-list w-list-unstyled"
                >
                  <li className="account-list-item">
                    <div className="account-list-text">
                      subscription status: active
                    </div>
                  </li>
                  <li className="account-list-item">
                    <div className="account-list-text">
                      subscription plan: {user.subscription_type}
                    </div>
                  </li>
                  <li className="account-list-item">
                    <div className="account-list-text">
                      instagram handle:{" "}
                      {user.handle == "@" ? "N/A" : user.handle}
                    </div>
                  </li>
                  <li className="account-list-item">
                    <a
                      onClick={this.handleShowModal}
                      className="cancel-subs-btn"
                    >
                      cancel subscription
                    </a>
                  </li>
                  {/* <li className="account-list-item">
                    <a onClick={this.handleShowModal} className="del-acc-btn">
                      Delete Account
                    </a>
                  </li> */}
                  <li className="account-list-item">
                    <a onClick={() => logoutUser()} className="logout-btn">
                      logout
                    </a>
                  </li>
                </ol>
              </div>
              <div className="account-img" />
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Cancel Subscription
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Are you sure?</h4>
            <p>
              If you cancel your subscription, you will loose access to our
              premium services.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="dark"
              onClick={() => this.setState({ showModal: false })}
              style={{ borderRadius: 0 }}
            >
              No, I'll keep my membership
            </Button>
            <Button
              style={{ borderRadius: 0, backgroundColor: "black" }}
              variant="dark"
              onClick={() =>
                this.handleCancelSubscription(
                  user.subcription_id,
                  user.stripe_customer_id
                )
              }
            >
              Proceed
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete Account
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Are you sure?</h4>
            <p>This action is irreversible.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="dark"
              onClick={() => this.setState({ showModal: false })}
              style={{ borderRadius: 0 }}
            >
              Cancel
            </Button>
            <Button
              style={{ borderRadius: 0, backgroundColor: "black" }}
              variant="dark"
              onClick={this.handleCancelSubscription}
            >
              Proceed
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Loading Modal */}
        <Modal style={{ marginTop: 160 }} show={this.state.showLoadingModal}>
          <Modal.Header>
            <Modal.Title style={{ textAlign: "center", margin: "auto" }}>
              Loading
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: 90 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              Sending...
              <Spinner style={{ marginTop: 24 }} animation="border" />
            </div>
          </Modal.Body>
        </Modal>
        <Footer />
      </div>
    );
  }
}
