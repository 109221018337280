import React from "react";
import DiscountCard from "./../components/DiscountCard";
import MainNav from "../components/navigation/MainNav";
import Footer from "../components/navigation/Footer";
import { fetchDiscountCodes } from "./../utils/Firebase/store";

export default class Discounts extends React.Component {
  state = {
    discountCodes: [],
  };

  componentDidMount() {
    this.handleFetchDiscountCodes();
  }

  handleFetchDiscountCodes() {
    fetchDiscountCodes()
      .then((code) => {
        this.setState({ discountCodes: code });
      })
      .catch((err) => {
        console.log("error:", err.message);
      });
  }
  render() {
    return (
      <React.Fragment>
        <MainNav />
        <div class="discounts">
          <div class="discounts-container w-container">
            <h1 class="discounts-h1">Discount Codes</h1>
            <div class="discounts-paragraph">
              Exclusive discount codes from selected brands
            </div>
            <div class="discounts-group">
              {this.state.discountCodes.map((discount, index) => (
                <DiscountCard key={index} discount={discount} />
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
