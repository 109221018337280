import React from "react";
import Fade from "react-reveal/Fade";

export default class ReviewCard extends React.Component {
  render() {
    return (
      <Fade>
        <div className="review-card">
          <img
            src={require("./../assets/images/stars.svg")}
            alt
            className="star-rating"
          />
          <p className="review-p">{this.props.review.review}</p>
          <div className="reviewer-group">
            <img
              src={this.props.review.img}
              sizes="(max-width: 479px) 45px, 60px"
              alt
              className="reviewer-avatar"
            />
            <div className="reviewer-handle">
              <strong className="review-handle">
                {this.props.review.handle}
              </strong>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}
