import React from "react";
import PlainNav from "../../components/navigation/PlainNav";
import { loginUser, resetPassword } from "./../../utils/Firebase/auth";
import { Modal, Spinner } from "react-bootstrap";
export default class Login extends React.Component {
  state = {
    email: "",
    password: "",
    showModal: false,
  };
  handleLogin = (event) => {
    event.preventDefault();
    this.setState({ showModal: true });
    loginUser(this.state.email, this.state.password)
      .then()
      .catch((err) => {
        this.setState({ showModal: false });
        alert(err.message);
      });
  };

  handleResetPassword = () => {
    if (this.state.email == "") {
      alert("Please enter your email!");
    } else {
      resetPassword(this.state.email);
    }
  };

  render() {
    return (
      <React.Fragment>
        <PlainNav />
        <div className="signup-body">
          <section id="contact-form" className="onboarding-form">
            <div className="dim-view" />
            <div
              data-w-id="c26e6013-a613-dd35-a7df-cc3a4f3a587f"
              className="onboarding-card"
            >
              <h2 className="onboarding-title">Welcome back</h2>
              <p className="onboarding-paragraph">
                helping you find the perfect fit.
              </p>
              <br />
              <br />
              <div className="onboarding-form-block w-form">
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  className="inner-oboarding-form"
                  onSubmit={this.handleLogin}
                >
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    className="w-input"
                    maxLength={256}
                    name="email"
                    data-name="Email"
                    placeholder
                    id="email"
                    required
                    value={this.state.email}
                    onChange={(newValue) => {
                      this.setState({
                        email: newValue.target.value,
                      });
                    }}
                  />
                  <label htmlFor="Password">password</label>
                  <input
                    type="password"
                    className="w-input"
                    maxLength={256}
                    name="Password"
                    data-name="Password"
                    placeholder
                    id="Password"
                    required
                    value={this.state.password}
                    onChange={(newValue) => {
                      this.setState({
                        password: newValue.target.value,
                      });
                    }}
                  />
                  <br />
                  <span style={{ marginTop: 48 }}>No account?</span>
                  <a className="onboarding-switch" href="/register">
                    {" "}
                    Register
                  </a>
                  <br />
                  <span>Forgot your password?</span>
                  <a
                    className="onboarding-switch"
                    onClick={this.handleResetPassword}
                  >
                    {" "}
                    Reset
                  </a>
                  <br />
                  <br />

                  <input
                    defaultValue="Login"
                    data-wait="Please wait..."
                    className="onboarding-submit-btn"
                    type="submit"
                  />
                </form>
              </div>
            </div>
          </section>
          <Modal style={{ marginTop: 160 }} show={this.state.showModal}>
            <Modal.Header>
              <Modal.Title style={{ textAlign: "center", margin: "auto" }}>
                Loading
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: 90 }}>
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Spinner animation="border" />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}
