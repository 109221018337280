import React from "react";
import FitsCard from "../components/FitsCard";
import ReviewCard from "../components/ReviewCard";
import Footer from "../components/navigation/Footer";
import HomeNav from "../components/navigation/HomeNav";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import { fetchFits } from "./../utils/Firebase/store";

class Home extends React.Component {
  state = {
    fits: [],
  };

  handleFetchFits = () => {
    fetchFits()
      .then((fits) => {
        this.setState({ fits });
      })
      .catch((err) => {
        alert("Error: Could not fetch fits");
      });
  };

  componentDidMount() {
    this.handleFetchFits();
  }

  render() {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("Persistent user:", user);
    return (
      <div>
        <HomeNav username={user.full_name} />
        {/* Hero */}
        <div className="signup-hero">
          <div className="hero-container">
            <div className="hero-div">
              <Bounce left>
                <img
                  src={require("./../assets/images/F4Dtransparent.png")}
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 400px, (max-width: 991px) 500px, 700px"
                  alt
                  className="hero-img"
                />
              </Bounce>
            </div>
          </div>
        </div>

        {/* Mission */}
        <Fade>
          <div className="mission">
            <div className="mission-container w-container">
              <h2 style={{ textAlign: "center" }}>
                helping you find the perfect fit
              </h2>
              <p className="mission-paragraph alt">
                Welcome to Fits 4 Days, a platform where you are privy to a
                personal stylist, the latest trends and exclusive benefits from
                selected lifestyle and fashion brands.
              </p>
            </div>
            <div className="benefits-seperator-dark" />
          </div>
        </Fade>

        {/* Fits */}
        <div className="posts">
          <div className="posts-container w-container">
            {this.state.fits.length > 0 ? (
              <div>
                <h2 className="posts-heading">fits</h2>
                <div className="posts-grid">
                  {this.state.fits.map((fit, index) => (
                    <FitsCard key={index} fit={fit} />
                  ))}
                </div>
              </div>
            ) : (
              <div
                style={{
                  opacity: 0.6,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1>No fits yet...</h1>
                <img
                  style={{ maxWidth: 150, marginTop: 32 }}
                  src={require("./../assets/images/empty-box.png")}
                />
              </div>
            )}
          </div>
        </div>

        {/* CTA */}
        <div className="cta">
          <div className="cta-container w-container">
            <div className="cta-group">
              <Fade left>
                <div
                  id="w-node-a05d0d02498b-fb7198e9"
                  className="cta-mockup-group"
                >
                  <img
                    src={require("./../assets/images/f4d-mockup.svg")}
                    className="cta-img"
                  />
                  <img
                    src={require("./../assets/images/illustration-element-05.svg")}
                    className="cta-bg-img"
                  />
                </div>
              </Fade>
              <div id="w-node-141fb7dfbb63-fb7198e9" className="cta-text-group">
                <Fade right>
                  <div className="cta-text-grid">
                    <h1 className="cta-heading">stay updated</h1>
                    <div className="cta-description">
                      Follow our instagram page for the latest offers and client
                      images
                    </div>
                    <div className="spacer-32" />
                    <a
                      href="https://www.instagram.com/fits4days_/"
                      target="_blank"
                      className="cta-btn"
                    >
                      follow us
                    </a>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>

        {/* Reviews */}
        <div className="reviews">
          <div className="review-grid">
            {reviews.map((review, index) => (
              <ReviewCard key={index} review={review} />
            ))}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;

const fits = [
  {
    img: require("./../assets/images/post1.jpeg"),
    details: [
      {
        link:
          "https://www.farfetch.com/uk/shopping/men/palm-angels-logo-denim-jacket-item-14730540.aspx?size=19&amp%3Bstoreid=12592&amp%3Butm_sour=&utm_medium=affiliate&utm_source=553779&utm_campaign=admitad&utm_content=650957&utm_keyword=bf7bc186f3c0d412b8f7320c97eb8b2a&af_prt=admitadgmbh&pid=admitad1_int&clickid=bf7bc186f3c0d412b8f7320c97eb8b2a&af_siteid=553779&af_channel=affiliate&af_ad_id=650957&is_retargeting=true",
        title: "Palm Angels Logo Denim Jacket",
      },
      {
        link:
          "https://www.prettylittlething.com/black-strappy-straight-neck-bodycon-dress.html?utm_source=google&amp;utm_medium=cpc&amp;utm_campaign=google_shopping&amp;utm_content=u",
        title: "PLT black strappy straight neck bodycon dress",
      },
      {
        link:
          "https://www.balenciaga.com/gb/coats_cod41964267vb.html#/gb/men/fall-20-men",
        title: "Shoes - @Balenciaga Black Jumpers",
      },
    ],
  },
  {
    img: require("./../assets/images/post2.jpeg"),
    details: [
      {
        link:
          "https://www.balenciaga.com/gb/coats_cod41964267vb.html#/gb/men/fall-20-men",
        title: "Top - @Balenciaga Black Top",
      },
      {
        link:
          "https://www.balenciaga.com/gb/coats_cod41964267vb.html#/gb/men/fall-20-men",
        title: "Bottom - @Balenciaga Black Jeans",
      },
      {
        link:
          "https://www.balenciaga.com/gb/coats_cod41964267vb.html#/gb/men/fall-20-men",
        title: "Shoes - @Balenciaga Black Jumpers",
      },
    ],
  },
  {
    img: require("./../assets/images/post3.jpeg"),
    details: [
      {
        link:
          "https://www.balenciaga.com/gb/coats_cod41964267vb.html#/gb/men/fall-20-men",
        title: "Top - @Balenciaga Black Top",
      },
      {
        link:
          "https://www.balenciaga.com/gb/coats_cod41964267vb.html#/gb/men/fall-20-men",
        title: "Bottom - @Balenciaga Black Jeans",
      },
      {
        link:
          "https://www.balenciaga.com/gb/coats_cod41964267vb.html#/gb/men/fall-20-men",
        title: "Shoes - @Balenciaga Black Jumpers",
      },
    ],
  },
  {
    img: require("./../assets/images/post4.jpeg"),
    details: [
      {
        link:
          "https://www.balenciaga.com/gb/coats_cod41964267vb.html#/gb/men/fall-20-men",
        title: "Top - @Balenciaga Black Top",
      },
      {
        link:
          "https://www.balenciaga.com/gb/coats_cod41964267vb.html#/gb/men/fall-20-men",
        title: "Bottom - @Balenciaga Black Jeans",
      },
      {
        link:
          "https://www.balenciaga.com/gb/coats_cod41964267vb.html#/gb/men/fall-20-men",
        title: "Shoes - @Balenciaga Black Jumpers",
      },
    ],
  },
  {
    img: require("./../assets/images/post6.jpeg"),
    details: [
      {
        link:
          "https://www.balenciaga.com/gb/coats_cod41964267vb.html#/gb/men/fall-20-men",
        title: "Top - @Balenciaga Black Top",
      },
      {
        link:
          "https://www.balenciaga.com/gb/coats_cod41964267vb.html#/gb/men/fall-20-men",
        title: "Bottom - @Balenciaga Black Jeans",
      },
      {
        link:
          "https://www.balenciaga.com/gb/coats_cod41964267vb.html#/gb/men/fall-20-men",
        title: "Shoes - @Balenciaga Black Jumpers",
      },
    ],
  },
  {
    img: require("./../assets/images/post7.jpeg"),
    details: [
      {
        link:
          "https://www.balenciaga.com/gb/coats_cod41964267vb.html#/gb/men/fall-20-men",
        title: "Top - @Balenciaga Black Top",
      },
      {
        link:
          "https://www.balenciaga.com/gb/coats_cod41964267vb.html#/gb/men/fall-20-men",
        title: "Bottom - @Balenciaga Black Jeans",
      },
      {
        link:
          "https://www.balenciaga.com/gb/coats_cod41964267vb.html#/gb/men/fall-20-men",
        title: "Shoes - @Balenciaga Black Jumpers",
      },
    ],
  },
];

const reviews = [
  {
    handle: "@adelayoadedayo",
    review:
      "— This is so classy exactly how I wanted it, I couldn’t even pick out of the three you sent, thank you so much! I will Defo be using you again",
    img: require("./../assets/images/client-01.png"),
  },
  {
    handle: "@mb_grillo",
    review:
      "— Wow that was quick! I like all 3 this was hard but the amiri shirt on this one🤩 , I’ve never seen this before , thank you so much.",
    img: require("./../assets/images/client-02.png"),
  },
  {
    handle: "@itsbee__",
    review:
      "— FITS4DAYS!!! I can’t wait to get these trainers now! I’m gonna get all 3 outfits! everything goes so well together! I’ll definitely be back girl🤪.",
    img: require("./../assets/images/client-03.png"),
  },
];
