import React from "react";
import MainRouter from "./routes/MainRouter";
import { withRouter } from "react-router-dom";
import firebase from "./utils/Firebase/Firebase";
import { fetchUser } from "./utils/Firebase/auth";
import LoginRouter from "./routes/LoginRouter";
import SubscriptionRouter from "./routes/SubscriptionRouter";
import LoadingScreen from "./components/LoadingScreen";

class App extends React.Component {
  state = {
    isSubscribed: false,
    isLoggedIn: false,
    isLoading: true,
  };

  checkAuthStatus = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        fetchUser()
          .then((user) => {
            this.setState({
              isLoggedIn: true,
              isSubscribed: user.is_subscribed,
              isLoading: false,
            });
          })
          .catch((err) => {
            console.log("err:", err);
          });
      } else {
        this.setState({
          isLoggedIn: false,
          isSubscribed: false,
          isLoading: false,
        });
      }
    });
  };

  componentDidMount() {
    // document.body.classList.add("is-loaded");
    // this.refs.scrollReveal.init();
    this.checkAuthStatus();
  }

  // // Route change
  // componentDidUpdate(prevProps) {
  //   if (this.props.location.pathname !== prevProps.location.pathname) {
  //     this.refs.scrollReveal.init();
  //   }
  // }

  render() {
    if (this.state.isLoading) {
      return <LoadingScreen />;
    }
    if (this.state.isLoggedIn) {
      if (this.state.isSubscribed) {
        return <MainRouter />;
      } else {
        return <SubscriptionRouter />;
      }
    }

    return <LoginRouter />;
  }
}

// export default withRouter((props) => <App {...props} />);
export default App;
