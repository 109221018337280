import React from "react";
import {
  Nav,
  Navbar,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { logoutUser } from "./../../utils/Firebase/auth";
export default class HomeNav extends React.Component {
  render() {
    const name = this.props.username.split(" ")[0];
    return (
      <Navbar
        // bg="light"
        expand="lg"
        className="sticky-top home-nav"
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link className="home-nav-items" href="/">
              Home
            </Nav.Link>
            <Nav.Link className="home-nav-items" href="/services">
              Services
            </Nav.Link>
            <Nav.Link className="home-nav-items" href="/discounts">
              Discounts
            </Nav.Link>
            <NavDropdown
              className="home-nav-dropdown"
              style={{}}
              title={<span className="text-p my-auto">Hi {name}</span>}
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item style={{ fontSize: 12 }} href="/account">
                Account
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                style={{ fontSize: 12 }}
                onClick={() => logoutUser()}
              >
                Log out
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
