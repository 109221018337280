import React from "react";
import MainNav from "../../components/navigation/MainNav";
import { Modal, Button, Spinner } from "react-bootstrap";
import * as emailjs from "emailjs-com";

export default class SourcingItemsForm extends React.Component {
  user = JSON.parse(localStorage.getItem("user"));
  state = {
    type: "Sourcing Items",
    first_name: this.user.full_name.split(" ")[0],
    last_name: "",
    email: this.user.email,
    details: "",
    service_type: "Sourcing Items",
    upload_file: null,
    showModal: false,
    showLoadingModal: false,
  };

  handleSendEmail = (e) => {
    e.preventDefault();
    this.setState({ showLoadingModal: true });
    emailjs
      .sendForm(
        "gmail",
        "sourcing_template",
        "mail-form",
        "user_0UsijEpODaMwDSga7ngmR"
      )
      .then(() => {
        this.setState({ showModal: true, showLoadingModal: false });
        // nothing...
      })
      .catch((err) => {
        console.log("Form Error:", err.text);
        alert(
          "Attachments size limit. The maximum allowed attachments size is 500Kb"
        );
      });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false, showLoadingModal: false });
    window.location.href = "/";
  };
  render() {
    return (
      <React.Fragment>
        <MainNav />
        <section id="contact-form" className="service-form">
          <div className="service-form-container w-container">
            <h2 className="service-form-h1">sourcing items</h2>
            <p className="service-form-p">
              *Please enquire at least one week before*
            </p>
            <div id="formInstructions" className="service-form-p-it">
              <em>Fields marked with an asterisk (*) are required.</em>
            </div>
            <div className="service-form-block w-form">
              <form
                id="mail-form"
                name="wf-form-Contact-Form"
                data-name="Contact Form"
                className="service-form"
                onSubmit={this.handleSendEmail}
              >
                <div className="service-form-grid">
                  <div id="w-node-faea0385786a-a1f8f753">
                    <label htmlFor="First-Name" id="contact-first-name">
                      First name *
                    </label>
                    <input
                      type="text"
                      className="w-input"
                      maxLength={256}
                      name="first_name"
                      data-name="First Name"
                      id="First-Name"
                      required
                      value={this.state.first_name}
                      onChange={(newValue) => {
                        this.setState({
                          first_name: newValue.target.value,
                        });
                      }}
                    />
                  </div>
                  <div id="w-node-faea0385786e-a1f8f753">
                    <label htmlFor="Last-Name" id="contact-last-name">
                      Last name *
                    </label>
                    <input
                      type="text"
                      className="w-input"
                      maxLength={256}
                      name="last_name"
                      data-name="Last Name"
                      id="Last-Name"
                      required
                      value={this.state.last_name}
                      onChange={(newValue) => {
                        this.setState({
                          last_name: newValue.target.value,
                        });
                      }}
                    />
                  </div>
                  <div id="w-node-faea03857872-a1f8f753">
                    <label htmlFor="Email" id="contact-email">
                      Email *
                    </label>
                    <input
                      type="email"
                      className="w-input"
                      maxLength={256}
                      name="email"
                      data-name="Email"
                      id="Email"
                      required
                      value={this.state.email}
                      onChange={(newValue) => {
                        this.setState({
                          email: newValue.target.value,
                        });
                      }}
                    />
                  </div>
                  <div id="w-node-faea03857876-a1f8f753">
                    <label htmlFor="Upload-File" id="upload">
                      upload file * (Max. size: 500kb)
                    </label>
                    <input
                      type="file"
                      className="w-input"
                      maxLength={256}
                      name="upload_file"
                      data-name="Contact Phone Number"
                      id="img"
                      accept="image/*"
                      value={this.state.upload_file}
                      onChange={(newValue) => {
                        this.setState({
                          upload_file: newValue.target.value,
                        });
                      }}
                    />
                  </div>
                  <div id="w-node-5d7ce5c45ffb-1b54c4c2">
                    <label htmlFor="Gender" id="gender">
                      Service type
                    </label>
                    <input
                      type="text"
                      className="w-input"
                      maxLength={256}
                      name="service_type"
                      value={this.state.service_type}
                    />
                  </div>
                  <div id="w-node-faea0385787a-a1f8f753">
                    <label htmlFor="Message" id="contact-message">
                      tell us more
                    </label>
                    <textarea
                      data-name="Message"
                      maxLength={5000}
                      id="Message"
                      name="description"
                      className="textarea w-input"
                      defaultValue={""}
                      value={this.state.details}
                      onChange={(newValue) => {
                        this.setState({
                          details: newValue.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <input
                  type="submit"
                  defaultValue="Submit"
                  data-wait="Please wait..."
                  className="service-cta"
                />
              </form>
              <Modal show={this.state.showModal}>
                <Modal.Header>
                  <Modal.Title>Form Sent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  We received your submission and will get back to you ASAP!
                  Thanks
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseModal}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* Loading Modal */}
              <Modal
                style={{ marginTop: 160 }}
                show={this.state.showLoadingModal}
              >
                <Modal.Header>
                  <Modal.Title style={{ textAlign: "center", margin: "auto" }}>
                    Loading
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: 90 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    Sending...
                    <Spinner style={{ marginTop: 24 }} animation="border" />
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
