// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyD8k9yoSfWw0hM9pZGiy_unVHN17Nfydvg",
  authDomain: "fits4days-8932b.firebaseapp.com",
  databaseURL: "https://fits4days-8932b.firebaseio.com",
  projectId: "fits4days-8932b",
  storageBucket: "fits4days-8932b.appspot.com",
  messagingSenderId: "684795094614",
  appId: "1:684795094614:web:307358e3538926e572bdf7",
  measurementId: "G-L9626WDX40",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase;
