import firebase from "./Firebase";
import "firebase/functions";
import { logoutUser } from "./auth";
import { unSubscribeUser } from "./store";

export const fetchStripeMonthlySession = () => {
  return new Promise((resolve, reject) => {
    var createStripeSession = firebase
      .functions()
      .httpsCallable("createStripeMonthlySession");
    createStripeSession()
      .then((result) => {
        // Read result of the Cloud Function.
        resolve(result.data);
      })
      .catch((error) => {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
        reject(error);
        console.log(code);
        console.log(message);
        console.log(details);
      });
  });
};

export const fetchStripeYearlySession = () => {
  return new Promise((resolve, reject) => {
    var createStripeSession = firebase
      .functions()
      .httpsCallable("createStripeYearlySession");
    createStripeSession()
      .then((result) => {
        // Read result of the Cloud Function.
        resolve(result.data);
      })
      .catch((error) => {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
        reject(error);
        console.log(code);
        console.log(message);
        console.log(details);
      });
  });
};

export const sendEmail = (message_data) => {
  var addMessage = firebase.functions().httpsCallable("sendServiceEmail");
  addMessage({ data: message_data })
    .then(function (result) {
      // Read result of the Cloud Function.
      var sanitizedMessage = result.data.text;
    })
    .catch(function (error) {
      // Getting the Error details.
      var code = error.code;
      var message = error.message;
      var details = error.details;
      // ...
    });
};

export const cancelSubscription = (subscription_id, stripe_customer_id) => {
  return new Promise((resolve, reject) => {
    var addMessage = firebase.functions().httpsCallable("cancelSubscription");
    addMessage({
      subscription_id: subscription_id,
      stripe_customer_id: stripe_customer_id,
    })
      .then(function (result) {
        // Read result of the Cloud Function.
        console.log("RESULT:", result);
        unSubscribeUser()
          .then(() => {
            alert(result.data);
            logoutUser();
          })
          .catch((err) => {
            console.log("Error:", err);
          });

        resolve(result.data);
      })
      .catch(function (error) {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
        // ...
        console.log("MESSAGE:", message);
        console.log("Details:", error);
        alert(message);
        reject(message);
      });
  });
};
