import React from "react";
import MainNav from "../../components/navigation/MainNav";
import { Modal, Button } from "react-bootstrap";
import * as emailjs from "emailjs-com";

export default class VirtualPersonalStylingForm extends React.Component {
  user = JSON.parse(localStorage.getItem("user"));

  state = {
    type: "Virtual Personal Styling",
    first_name: this.user.full_name.split(" ")[0],
    last_name: "",
    email: this.user.email,
    gender: "",
    event_type: "",
    date_needed: "",
    details: "",
    showModal: false,
    service_type: "Virtual Personal Styling",
  };

  handleSendEmail = (e) => {
    e.preventDefault();
    this.setState({ showModal: true });
    // sendEmail(this.state);
    emailjs
      .sendForm(
        "gmail",
        "services_template",
        "mail-form",
        "user_0UsijEpODaMwDSga7ngmR"
      )
      .then(() => {
        // nothing...
      })
      .catch((err) => {
        console.log("email-js error:", err);
      });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
    window.location.href = "/";
  };

  render() {
    return (
      <React.Fragment>
        <MainNav />
        <section id="contact-form" className="service-form">
          <div className="service-form-container w-container">
            <h2 className="service-form-h1">Virtual Personal Styling</h2>
            <p className="service-form-p">
              *Please enquire at least one week before*
            </p>
            <div id="formInstructions" className="service-form-p-it">
              <em>Fields marked with an asterisk (*) are required.</em>
            </div>
            <div className="service-form-block w-form">
              <form
                id="mail-form"
                name="wf-form-Contact-Form"
                data-name="Contact Form"
                className="service-form"
              >
                <div className="service-form-grid">
                  <div id="w-node-e1cb7d1965f5-925c45d8">
                    <label htmlFor="First-Name" id="contact-first-name">
                      First name *
                    </label>
                    <input
                      type="text"
                      className="w-input"
                      maxLength={256}
                      name="first_name"
                      data-name="First Name"
                      id="First-Name"
                      required
                      value={this.state.first_name}
                      onChange={(newValue) => {
                        this.setState({
                          first_name: newValue.target.value,
                        });
                      }}
                    />
                  </div>
                  <div id="w-node-e1cb7d1965f9-925c45d8">
                    <label htmlFor="Last-Name" id="contact-last-name">
                      Last name *
                    </label>
                    <input
                      type="text"
                      className="w-input"
                      maxLength={256}
                      name="last_name"
                      data-name="Last Name"
                      id="Last-Name"
                      required
                      value={this.state.last_name}
                      onChange={(newValue) => {
                        this.setState({
                          last_name: newValue.target.value,
                        });
                      }}
                    />
                  </div>
                  <div id="w-node-e1cb7d1965fd-925c45d8">
                    <label htmlFor="Email" id="contact-email">
                      Email *
                    </label>
                    <input
                      type="email"
                      className="w-input"
                      maxLength={256}
                      name="email"
                      data-name="Email"
                      id="Email"
                      required
                      value={this.state.email}
                      onChange={(newValue) => {
                        this.setState({
                          email: newValue.target.value,
                        });
                      }}
                    />
                  </div>
                  {/* <div id="w-node-e1cb7d196601-925c45d8">
                    <label htmlFor="Contact-Phone-Number" id="contact-phone">
                      Phone number
                    </label>
                    <input
                      type="tel"
                      className="w-input"
                      maxLength={256}
                      name="Contact-Phone-Number"
                      data-name="Contact Phone Number"
                      id="Contact-Phone-Number"
                    />
                  </div> */}
                  <div>
                    <label htmlFor="Gender-3">gender *</label>
                    <input
                      type="text"
                      className="w-input"
                      maxLength={256}
                      name="gender"
                      data-name="Gender"
                      id="Gender-3"
                      required
                      value={this.state.gender}
                      onChange={(newValue) => {
                        this.setState({
                          gender: newValue.target.value,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="Event-Type">event type*</label>
                    <input
                      type="text"
                      className="w-input"
                      maxLength={256}
                      name="event_type"
                      data-name="Event Type"
                      id="Event-Type"
                      required
                      value={this.state.event_type}
                      onChange={(newValue) => {
                        this.setState({
                          event_type: newValue.target.value,
                        });
                      }}
                    />
                  </div>
                  <div id="w-node-5d7ce5c45ffb-1b54c4c2">
                    <label htmlFor="Gender" id="gender">
                      Service type
                    </label>
                    <input
                      type="text"
                      className="w-input"
                      maxLength={256}
                      name="service_type"
                      value={this.state.service_type}
                    />
                  </div>
                  <div>
                    <label htmlFor="Date-of-event-2">
                      Date of event (YYYY-MM-DD) *
                    </label>
                    <input
                      type="date"
                      className="w-input"
                      maxLength={256}
                      name="date_needed"
                      data-name="Date of event"
                      id="Date-of-event-2"
                      required
                      value={this.state.date_needed}
                      onChange={(newValue) => {
                        this.setState({
                          date_needed: newValue.target.value,
                        });
                      }}
                    />
                  </div>
                  <div
                    id="w-node-e1cb7d196605-925c45d8"
                    className="message-field"
                  >
                    <label htmlFor="Message" id="contact-message">
                      Tell us more
                    </label>
                    <textarea
                      data-name="Message"
                      maxLength={5000}
                      id="Message"
                      name="description"
                      required
                      className="w-input"
                      defaultValue={""}
                      value={this.state.details}
                      onChange={(newValue) => {
                        this.setState({
                          details: newValue.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <input
                  defaultValue="Submit"
                  data-wait="Please wait..."
                  className="service-cta"
                  onClick={this.handleSendEmail}
                />
              </form>
              <Modal show={this.state.showModal}>
                <Modal.Header>
                  <Modal.Title>Form Sent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  We received your submission and will get back to you ASAP!
                  Thanks
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseModal}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
